import React from "react";
import { getLanguageCode } from "../../helpers/languages";
import { useState } from "react";
const Footer = () => {
    const [language, setLanguage] = useState(getLanguageCode(localStorage.getItem('languageCode')?localStorage.getItem('languageCode'):'en'))

    return (
        <React.Fragment>
            {/*start overlay*/}
            <div className="overlay toggle-btn-mobile" />
            {/*end overlay*/}
            {/*Start Back To Top Button*/}{" "}
            <a href="#" className="back-to-top">
                <i className="bx bxs-up-arrow-alt" />
            </a>
            {/*End Back To Top Button*/}
            {/*footer */}
            <div className="footer">
                <p className="mb-0 brand-color">
                    {language.COPYRIGHT} @ Youuup 2023
                </p>
            </div>
            {/* end footer */}
        </React.Fragment >
    )
}

export default Footer;