import React, { useEffect } from 'react';
import { Modal, } from "reactstrap";
import ChannelsList from '../../components/Channels/ChannelsList';
import { useState } from 'react';
import channelsServices from '../../services/channels.services';
import thematicChannelsServices from '../../services/thematicChannels.services';
import ChannelForm from '../../components/Channels/ChannelsForm';
import PageBreadcrumb from '../../components/Comman/PageBreadcrumb';
import { getLanguageCode } from '../../helpers/languages';
import { toast } from 'react-toastify';


const Channels = () => {
    const [modal, setModal] = React.useState(false);
    const toggle = () => setModal(!modal);
    const [getChannels, setGetChannels] = useState([]);
    const [getThematicChannelsList, setGetThematicChannelsList] = useState([]);
    const [selectedRow, setSelectedRow] = useState();
    const [isAddMode, setIsAddMode] = useState('Add');
    const [language, setLanguage] = useState(getLanguageCode(localStorage.getItem('languageCode')?localStorage.getItem('languageCode'):'en'))


    const getThematicChannels = async () => {
        let result = await thematicChannelsServices.getThematicList();
        result = result.data.result;
        setGetThematicChannelsList(result);       
    };

    const getChannelsList = async () => {
        let result = await channelsServices.getChannelsData();       
        result = result.data.result;       
        setGetChannels(result);        
    };

    const deleteById = async (id) => {
        let result = await channelsServices.deleteChannelById({channel_id : id});
        if (result.data.status === true) {
            toast.success('Channel deleted successfully', { toastId: 'channelDelete_success', autoClose: 3000 });
            getChannelsList();
        } else {
            toast.error(result.data.message, { toastId: 'channelDelete_faild', autoClose: 3000 });
        }
    }

    const filterCHannelList = getChannels.map((getChannels)=>{
        getThematicChannelsList.map((getThematicChannelsList)=>{
            if(getChannels.thematic_channel_id===getThematicChannelsList.id){
                getChannels.thematic_channel_name = getThematicChannelsList.thematic_channel_name
            }
        })
        return getChannels
    });
    

    useEffect(() => {
        getThematicChannels()
        getChannelsList()
    },[])

    return (
        <>
            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <PageBreadcrumb pageTitle={language.CHANNELS} />
                <div className="ms-auto">
                    <div className="btn-group">
                        <button className="btn btn-primary btn-sm"
                            onClick={() => {
                                toggle();
                                setIsAddMode('Add')
                            }}>{language.ADD}</button>
                        <Modal isOpen={modal} toggle={toggle} size="md">
                            <ChannelForm toggle={toggle} isAddMode={isAddMode} selectedRow={selectedRow} getThematicChannelsList={getThematicChannelsList} language={language} getChannelsList={getChannelsList} />
                        </Modal>
                    </div>
                </div>
            </div>
            <div className='card'>
                <div className='card-body'>
                    <ChannelsList getChannels={getChannels} setIsAddMode={setIsAddMode} setSelectedRow={setSelectedRow} setModal={setModal} language={language} getChannelsList={getChannelsList} deleteById={deleteById} />
                </div>
            </div>
        </>
    );
};

export default Channels;