import React, { useState } from "react";
import logoIcon from '../../assets/images/logo-icon.png'
import { Dropdown, DropdownMenu } from "reactstrap";
import { Link } from "react-router-dom";
import { getLanguageCode } from "../../helpers/languages";

const Header = ({ menuIconOnClick }) => {
    const [profileToggle, setProfileToggle] = useState(false);
    const [modal, setModal] = React.useState(false);
    const [language, setLanguage] = useState(getLanguageCode(localStorage.getItem('languageCode')?localStorage.getItem('languageCode'):'en'))

    const onCLickProfile = () => {
        setProfileToggle((prevState) => prevState ? false : true)
    }
    const toggle = () => {
        setModal((prevState) => prevState ? false : true)
    }
    const logOut = () => {
        localStorage.removeItem("auth");
        localStorage.removeItem("user")
    }
   
    return (
        <React.Fragment>
            <>
                {/*header*/}
                <header className="top-header">
                    <nav className="navbar navbar-expand">
                        <div className="left-topbar d-flex align-items-center">
                            <Link to="#" className="toggle-btn">
                                <i className="bx bx-menu" onClick={menuIconOnClick} />
                            </Link>
                        </div>
                        {/* <div className="flex-grow-1 search-bar">
                            <div className="input-group">
                                <button
                                    className="btn btn-search-back search-arrow-back"
                                    type="button"
                                >
                                    <i className="bx bx-arrow-back" />
                                </button>
                                <input type="text" className="form-control" placeholder="Search...." />
                                <button className="btn btn-search" type="button">
                                    <i className="lni lni-search-alt" />
                                </button>
                            </div>
                        </div> */}
                        <div className="right-topbar ms-auto">
                            <ul className="navbar-nav">
                                <li className={`nav-item dropdown dropdown-user-profile ${profileToggle ? 'show' : ''}`}>
                                    <Link
                                        className="nav-link dropdown-toggle dropdown-toggle-nocaret pe-0"
                                        to="#"
                                        data-bs-toggle="dropdown"
                                        onClick={toggle}
                                    >
                                        <div className="d-flex user-box align-items-center">
                                            <div className="user-info">
                                                <p className="user-name mb-0">{language.ADMIN}</p>
                                                <p className="designattion mb-0">{language.AVAILABLE}</p>
                                            </div>
                                            <img
                                                src={logoIcon}
                                                className="user-img"
                                                alt="user avatar"
                                            />
                                        </div>
                                    </Link>
                                    <Dropdown isOpen={modal} toggle={toggle}>
                                        <DropdownMenu>
                                            <Link className="dropdown-item" to="/profile" onClick={toggle}>
                                                <i className="bx bx-user" />
                                                <span>{language.PROFILE}</span>
                                            </Link>
                                            <div className="dropdown-divider m-0" />
                                            <Link to="/logout" className="dropdown-item" >
                                                <i className="bx bx-power-off" />
                                                <span>{language.LOGOUT}</span>
                                            </Link>
                                        </DropdownMenu>
                                    </Dropdown>

                                </li>
                            </ul>
                        </div>
                    </nav>
                </header>
                {/*end header*/}
            </>

        </React.Fragment>
    )
}

export default Header;