import en from '../languages/en.js'
import sp from '../languages/sp.js'

const getLanguageCode = languageCode => {
  switch (languageCode) {
    case 'en':
      return en;
      break;
    case 'sp':
      return sp;
      break;
    default:
      return en;
  }
}

export {
  getLanguageCode
}