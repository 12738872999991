import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import { ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import thematicChannelsServices from '../../services/thematicChannels.services';
import { toast } from 'react-toastify';
import TextError from '../../helpers/FormikHelper/TextError';
import { FieldSchema } from '../../schema/FieldSchema';

const ThematicChannelForm = ({ toggle, isAddMode, getThematicChannels, selectedRow, language }) => {
  const initialValues = {
    thematic_channel_name: '',
  }

  const savedValues = {
    thematic_channel_name: selectedRow?.thematic_channel_name
  }

  const onSubmit = async (values) => {
    const reqBody = {
      thematic_channel_name: values.thematic_channel_name
    }
    if(isAddMode === 'Add'){
      let result = await thematicChannelsServices.addThematic(reqBody);
      if(result.data.status === true){
        toggle()
        getThematicChannels()
        toast.success('Add thematic channels successfully', {toastId: 'registration_success', autoClose: 3000 });
      } else {
        toast.error(result.data.message, { toastId: 'registration_fail', autoClose: 3000 });
      }
      
    } else {
      const reqBody = {
        thematic_channel_name: values.thematic_channel_name,
        thematic_channel_id: selectedRow?.id
      }
      
      const result = await thematicChannelsServices.updateThematicById(reqBody);
      if(result.data.status === true){
        toggle()
        getThematicChannels()
        toast.success('Update thematic channels successfully', {toastId: 'update_success', autoClose: 3000 });
      } else{
        toast.error(result.data.message, {toastId: 'update_fail', autoClose: 3000 });
      }
    }

  }



  return (
    <React.Fragment>
      <Formik
        initialValues={isAddMode === 'Add' ? initialValues : savedValues}
        validationSchema={FieldSchema('THEMATIC_CHANNEL')}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ setFieldValue, values }) => (<Form>
          <ModalHeader toggle={toggle} className='border-0 pb-0'>
            <div className="card-title d-flex align-items-center mb-0">
              <h5 className="mb-0 text-primary">{isAddMode === 'Add' ? `${language.ADD_THEMATIC_CHANNEL}` : `${language.UPDATE_THEMATIC_CHANNEL}`}</h5>
            </div>
          </ModalHeader>
          <ModalBody className="">
            <div className="row" >
              <div className="col-12">
                <label htmlFor="thematic_channel_name" className="form-label">
                  {language.THEMATIC_CHANNEL_NAME}
                </label>
                <Field type="text" className="form-control" id="thematic_channel_name" name='thematic_channel_name'
                />
                <ErrorMessage name='thematic_channel_name' component={TextError} />               
              </div>
            </div>
          </ModalBody>
          <ModalFooter className='border-0'>
            <button
              type="button"
              className="btn btn-secondary btn-sm"
              onClick={toggle}
            >
              {language.CLOSE}
            </button>
            <button type="submit" className="btn btn-primary btn-sm">
              {isAddMode === "Add" ? `${language.SAVE}` : `${language.UPDATE}`}
            </button>
          </ModalFooter>
        </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default ThematicChannelForm;