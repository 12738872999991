import React from 'react';

import error404 from '../../assets/images/errors-images/error404.png'
import { Link } from 'react-router-dom';
const Error404 = () => {
    return (
        <div className='pt-5 d-flex align-items-center juatify-content-center flex-column'>
            <img src={error404} />
            <Link to="/" className='btn btn-primary mt-4'>Back to Home</Link>    
        </div>
    );
};

export default Error404;