import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import { ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { toast } from 'react-toastify';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CMSServices from '../../services/CMS.services';
import TextError from '../../helpers/FormikHelper/TextError';
import { FieldSchema } from '../../schema/FieldSchema';

const CMSForm = ({ toggle, isAddMode, getCMSPageList, selectedRow, language }) => {
    const initialValues = {
        page_url: '',
        page_title: '',
        page_description: ''
    }

    const savedValues = {
        page_url: selectedRow?.page_url,
        page_title: selectedRow?.page_title,
        page_description: selectedRow?.page_description
    }

    const onSubmit = async (values) => {
        const reqBody = {
            page_url: values.page_url,
            page_title: values.page_title,
            page_description: values.page_description
        }
        if (isAddMode === 'Add') {
            let result = await CMSServices.addCMSPage(reqBody);
            if (result.data.status === true) {
                toggle()
                getCMSPageList()
                toast.success('Add new page successfully', { toastId: 'registration_success', autoClose: 3000 });
            } else {
                toast.error(result.data.message, { toastId: 'registration_fail', autoClose: 3000 });
            }

        } else {
            const reqBody = {
                id: selectedRow?.id,
                page_title: values.page_title,
                page_description: values.page_description
            }           
            const result = await CMSServices.updateCMSPage(reqBody);
            if (result.data.status === true) {
                toggle()
                getCMSPageList()
                toast.success('Update page successfully', { toastId: 'update_success', autoClose: 3000 });
            } else {
                toast.error('Update Page', { toastId: 'update_fail', autoClose: 3000 });
            }
        }
    }

    return (
        <React.Fragment>
            <Formik
                initialValues={isAddMode === 'Add' ? initialValues : savedValues}
                validationSchema={FieldSchema('CMS_PAGES')}
                onSubmit={onSubmit}
                enableReinitialize
            >
                {({ setFieldValue, values }) => (<Form>
                    <ModalHeader toggle={toggle} className='border-0 pb-0'>
                        <div className="card-title d-flex align-items-center mb-0">
                            <h5 className="mb-0 text-primary">{isAddMode === 'Add' ? `${language.ADD_PAGE}` : `${language.UPDATE_PAGE}`}</h5>
                        </div>
                    </ModalHeader>
                    <ModalBody className="">
                        <div className="row" >
                            <div className="col-12">
                                <label htmlFor="page_title" className="form-label">
                                    {language.NAME}
                                </label>
                                <Field type="text" className="form-control" id="page_title" name='page_title'
                                />
                                <ErrorMessage name='page_title' component={TextError} />
                            </div>
                            <div className="col-12 mt-3">
                                <label htmlFor="page_url" className="form-label">
                                    {language.URL}
                                </label>
                                <Field type="text"  className="form-control" id="page_url" name='page_url' disabled={isAddMode === 'Add'?'':'disabled'}/>
                                <ErrorMessage name='page_url' component={TextError} />
                            </div>
                            <div className="col-12 mt-3">
                                <label htmlFor="page_description" className="form-label">
                                    {language.DESCRIPTION}
                                </label>
                                <CKEditor
                                    
                                    editor={ClassicEditor}
                                    data={values.page_description}
                                    onReady={editor => {
                                        // You can store the "editor" and use when it is needed.
                                        // console.log('Editor is ready to use!', editor);
                                    }}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        // console.log({ event, editor, data });
                                        setFieldValue("page_description", data);
                                    }}
                                    onBlur={(event, editor) => {
                                        // console.log('Blur.', editor);
                                    }}
                                    onFocus={(event, editor) => {
                                        // console.log('Focus.', editor);
                                    }}
                                />
                                <ErrorMessage name='page_description' component={TextError} />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter className='border-0'>
                        <button
                            type="button"
                            className="btn btn-secondary btn-sm"
                            onClick={toggle}
                        >
                            {language.CLOSE}
                        </button>
                        <button type="submit" className="btn btn-primary btn-sm">
                            {isAddMode === "Add" ? `${language.SAVE}` : `${language.UPDATE}`}
                        </button>
                    </ModalFooter>
                </Form>
                )}
            </Formik>
        </React.Fragment>
    );
};

export default CMSForm;