import React, { useState } from 'react';
import PageBreadcrumb from '../../components/Comman/PageBreadcrumb';
import ChangePassword from '../../components/Users/ChangePassword';
import { getLanguageCode } from "../../helpers/languages";


const Profile = () => {
    const [language, setLanguage] = useState(getLanguageCode(localStorage.getItem('languageCode')?localStorage.getItem('languageCode'):'en'))

    return (
        <>
            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <PageBreadcrumb pageTitle={language.CHANGE_PASSWORD} />
            </div>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='card'>
                        <div className='card-body'>
                            <ChangePassword language={language} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Profile;