import React from 'react';
import { Row, Col, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { useMemo } from "react";
const { SearchBar } = Search;
const ChannelsList = ({ getChannels, getChannelsList, setSelectedRow, setIsAddMode, setModal, language, deleteById }) => {
    
    let dataList = getChannels.map((item, index)=>{
        item.srNo = index + 1
        return item
    })
   
    const tblColumn = useMemo(() => [
        {
            dataField: "srNo",
            text: `${language.SRNO}`,    
        },
        {
            dataField: "channel_name",
            text: `${language.CHANNEL_NAME}`,
            sort: true,
        },
        {
            dataField: "thematic_channel_name",
            text: `${language.THEMATIC_CHANNEL_NAME}`,
            sort: true,
        },
        {
            dataField: "",
            text: `${language.ACTION}`,
            formatter: (value, row) => {
                return (
                    // <button className='p-0 bg-transparent border-0'
                    //     onClick={() => {
                    //         setSelectedRow(row);
                    //         setIsAddMode("Edit");
                    //         setModal(true);
                    //     }}
                    // >
                    //     <i className="lni lni-pencil font-size-10 brand-color" />
                    // </button>
                    <UncontrolledDropdown>
                        <DropdownToggle className="card-drop cursor-pointer p-2" tag="a">
                            <i className="lni lni-more-alt font-size-16" />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end action_drop_down">
                            <DropdownItem
                                onClick={() => {
                                    setSelectedRow(row);
                                    setIsAddMode("Edit");
                                    setModal(true);
                                }}
                            >
                                <i className="lni lni-pencil font-size-16 text-success me-1" />
                                Edit
                            </DropdownItem>
                            <div className='my-1'></div>
                            <DropdownItem
                                onClick={() => {
                                    getChannelsList();
                                    deleteById(row.id);
                                }}
                            >
                                <i className="lni lni-trash font-size-16 text-danger me-1" />
                                Delete
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                );
            },
        },
    ]);

    const defaultSorted = [
        {
            dataField: "_id",
            order: "desc",
        },
    ];
    const pzipcodeOptions = {
        sizePerPzipcode: 10,
        totalSize: dataList.length, // replace later with size(customers),
        custom: true,
    };

    return (
        <>
            <PaginationProvider
                pagination={paginationFactory(pzipcodeOptions)}
                keyField="id"
                columns={tblColumn}
                data={dataList}
            >
                {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider keyField="_id" columns={tblColumn} data={dataList} search>
                        {(toolkitProps) => (
                            <React.Fragment>
                                <Row className="mb-2" style={{ display: "none" }}>
                                    <Col md="4">
                                        <div className="search-box me-2 mb-2 d-inline-block">
                                            <div className="status-relative"></div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl="12">
                                        <div className="text-right col-md-3 offset-md-9 mb-2">
                                            <label className="pe-3 text-white">{language.SEARCH}</label>
                                            <SearchBar
                                                className="w-100"
                                                {...toolkitProps.searchProps}
                                            />
                                        </div>
                                        <div className="table-responsive">
                                            <BootstrapTable
                                                noDataIndication={
                                                    <h6 className="d-flex justify-content-center">
                                                        {language.RECORD_NOT_FOUND}
                                                    </h6>
                                                }
                                                keyField={"id"}
                                                responsive
                                                bordered={false}
                                                striped={false}
                                                defaultSorted={defaultSorted}
                                                classes={
                                                    "table table-striped align-middle table-nowrap"
                                                }
                                                headerWrapperClasses={"thead-light"}
                                                {...toolkitProps.baseProps}
                                                {...paginationTableProps}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                {dataList.length > 0 && (
                                    <Row className="align-items-md-center mt-30">
                                        <Col className="inner-custom-pagination mt-2 d-flex">
                                            <div className="d-inline">
                                                <SizePerPageDropdownStandalone {...paginationProps} />
                                            </div>
                                            <div className="text-md-right ms-auto">
                                                <PaginationListStandalone {...paginationProps} />
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                            </React.Fragment>
                        )}
                    </ToolkitProvider>
                )}
            </PaginationProvider>
        </>
    );
};

export default ChannelsList;