import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { isAndroid, isIOS } from "react-device-detect";
const Test = () => {
  const [queryParameters] = useSearchParams();

  console.log("queryParameters", queryParameters.get("id"));
  useEffect(() => {
    let id = queryParameters.get("id");
    // window.open(`youuupapp://video/${id}`, "_self");
    if (isAndroid) {
        // const url =
        //   "intent://instagram.com/#Intent;scheme=https;package=com.instagram.android;end";
        // const url =`intent://youuupapp://video/${id}/#Intent;scheme=youuupapp;package=com.youuup;end`;
   
       
        // window.location = `youuupapp://video/${id}`;
        window.location=`youuupapp://video/${id}`
 setTimeout(function () { window.location = "https://play.google.com/store/apps/details?id=com.youuup"; }, 1500);
      } else if (isIOS) {
        // alert('isIOS', isIOS)
        window.location.replace(`youuupapp://video/${id}`);
  
        // setTimeout(() => {
        //   window.location.replace(
        //     "https://apps.apple.com/us/app/instagram/id389801252"
        //   );
        // }, 10000);
      } else {
        const url ="intent://youuuup.com/#Intent;scheme=youuupapp;package=com.youuup;end";
        // alert(url)
        // window.location.replace("https://instagram.com");
      }
  }, []);

  return (
    <React.Fragment>
      <div className="page-content text-center d-flex h-100 align-items-center justify-content-center flex-column">
        <>
          <div>
            <button
              className="btn btn-primary"
              type="button"
              onClick={() => {
                let id = queryParameters.get("id");
                window.open(`youuupapp://video/${id}`, "_blank");
              }}
            >
              Go To App{" "}
            </button>
          </div>
          <div className="mt-3">
            <button
              className="btn btn-primary"
              type="button"
              onClick={() => {
                let id = queryParameters.get("id");
                //  window.open(`youuupapp://video/${id}`, '_blank')
              }}
            >
              Go To Play Store{" "}
            </button>
          </div>
        </>
      </div>
    </React.Fragment>
  );
};

export default Test;
