import React, { useEffect } from 'react';

import DashboardCard from '../../components/Dashboard/DashboardCard';
import { useState } from 'react';
import userServices from '../../services/user.services';
import PageBreadcrumb from '../../components/Comman/PageBreadcrumb';
import { getLanguageCode } from '../../helpers/languages';


const Dashboard = () => {
    const [dashboard, setDashboard] = useState('');
    const [language, setLanguage] = useState(getLanguageCode(localStorage.getItem('languageCode') ? localStorage.getItem('languageCode') : 'en'))
    const getDashboardDetails = async () => {
        let result = await userServices.getDashboardData();
        result = result.data.result;
        setDashboard(result);
    }

    useEffect(() => {
        getDashboardDetails();
    }, []);

    return (
        <React.Fragment>
            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <PageBreadcrumb pageTitle={language.DASHBOARD} />
                <div className="ms-auto">
                </div>
            </div>
            <DashboardCard dashboard={dashboard} language={language} />
        </React.Fragment >
    );
};

export default Dashboard;