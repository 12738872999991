import { required, yup } from "./CommonValidation";
import * as Yup from 'yup';
export const FieldSchema = (type) => {
    if (type === 'THEMATIC_CHANNEL') {
        return yup({
            thematic_channel_name: required("Please enter Thematic Channel name").max(12, 'Max length must be 12').trim()
        });
    } else if (type === 'CHANNEL') {
        return yup({
            thematic_channel_id: required("Please select thematic channel"),
            channel_name: required("Please enter channel name").trim(),
        })
    } else if (type === 'CMS_PAGES') {
        return yup({
            page_url: required("Please enter page url"),
            page_title: required("Please enter page name"),
            page_description: required("Please enter description"),
        })
    } else if (type === 'CHANGE_PASSWORD') {
        return yup({
            old_password: required('Old Password is required'),
            new_password: required('New Password is required'),
            new_confirm_password: required('').oneOf([Yup.ref('new_password'), null], 'New Passwords must match')
        })
    } else if (type === 'USER_ADD') {
        return yup({
            name: required('Please enter User name'),
            email: required('Please enter email id').email('Please enter valid email'),
            password: required('Password is required'),
            user_type_id: required('Please select user type'),
            country: required('Please select country')
        })
    } else if (type === 'USER_EDIT') {
        return yup({
            name: required('Please enter User name'),
            email: required('Please enter email id').email('Please enter valid email'),
            user_type_id: required('Please select user type'),
            country: required('Please select country')
        })
    } else if (type === 'MESSAGES') {
        return yup({
            title: required('Please enter title'),
            user_type_id: required('Please select user type'),
            body: required('Please enter message')
        })
    }
};