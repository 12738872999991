export default {
  'SIGN_IN': 'Iniciar sesión',
  'YOUR_ACCOUNT': 'su cuenta',
  'EMAIL_ADDRESS': 'Dirección de correo electrónico',
  'ENTER_PASSWORD': 'Introducir la contraseña',
  'SIGN_IN': 'Iniciar sesión',
  'DASHBOARD': 'Panel',
  'USERS': 'Usuarias',
  'PROFILES': 'Perfiles',
  'CONTENT_CREATORS': 'Creadoras de contenido',
  'FREE_TRIAL': 'Prueba gratis',
  'FREE_BY_ADMIN': 'Free By Admin',
  'PAID_CONTENT_CREATORS': 'Creadoras de contenido pagado',
  'UNPAID_CONTENT_CREATORS': 'Creadoras de contenido sin pagar',
  'REPORTED_USER': 'Usuario reportada',
  'TOTAL_VIDEOS': 'Vídeos totales',
  'BLINK_VIDEOS': 'Vídeos parpadeantes',
  'LONG_VIDEOS': 'Vídeos largos',
  'SHORT_VIDEOS': 'Vídeos cortos',
  'THEMATIC_CHANNELS': 'Canales Temáticos',
  'CHANNELS': 'Canales',
  'VIDEOS': 'Vídeos',
  'CMS_PAGES': 'Páginas de CMS',
  'USER_DETAILS': 'Detalles de usuario',
  'PROFILE_DETAILS': 'Detalles del perfil',
  'EMAIL': 'Correo electrónico',
  'COUNTRY': 'País',
  'BIO': 'biografía',
  'EMAIL_COLLABORATION': 'Colaboración por correo electrónico',
  'USER_TYPE': 'Tipo de usuario',
  'TOTAL_FOLLOWERS': 'Seguidores totales',
  'TOTAL_FOLLOWING': 'Seguimiento total',
  'PAYMENT_STATUS': 'Estado de pago',
  'PROMOTION_TITLE_ONE': 'Promoción Título Uno',
  'PROMOTION_TITLE_ONE_LINK': 'Promoción Título Uno Enlace',
  'PROMOTION_TITLE_TWO': 'Promoción Título Dos',
  'PROMOTION_TITLE_TWO_LINK': 'Promoción Título Dos Enlace',
  'PROMOTION_TITLE_THREE': 'Promoción Título Tres',
  'PROMOTION_TITLE_THREE_LINK': 'Promoción Título Tres Enlace',
  'FREE_TRIAL': 'Prueba gratis',
  'PAID': 'Pagada',
  'USER': 'Usuaria',
  'CREATOR': 'Creadora',
  'SRNO': 'NO SEÑOR.',
  'NAME': 'Nombre',
  'NICK_NAME': 'Apodo',
  'STATUS': 'Estado',
  'ACTION': 'Accion',
  'RECORD_NOT_FOUND': 'Registro no encontrado',
  'SEARCH': 'Buscar',
  'BACK_TO_LIST': 'Volver a la lista',
  "THEMATIC_CHANNEL_NAME": 'Nombre del canal temático',
  'LOCKONOFF': 'Activar/desactivar bloqueo',
  'ADD': 'Agregar',
  'ADD_THEMATIC_CHANNEL': 'Agregar canal temático',
  'CLOSE': 'Cerca',
  'SAVE': 'Ahorrar',
  'UPDATE': 'Actualizar',
  'UPDATE_THEMATIC_CHANNEL': 'Actualizar canal temático',
  'CHANNEL_NAME': 'Nombre del Canal',
  'ADD_CHANNEL': 'Agregar canal',
  'UPDATE_CHANNEL':'Actualizar canal',
  'LIKE': 'Como',
  'SHARE': 'Compartir',
  'DATE': 'Fecha',
  'COMMENT' : 'Comentario',
  'FAVOURITE': 'Favorita',
  'VIDEO_ID': 'Identificación de video',
  'VIEW_VIDEO' : 'Ver vídeo',
  'REPORT_BY': 'Informe por',
  'REPORT_TO': 'Reportar a',
  'REPORT_MESSAGE': 'Mensaje de informe',
  'REPORT_DATE': 'Fecha del informe',
  'URL': 'URL',
  'ADD_PAGE': 'Añadir página',
  'DESCRIPTION': 'Descripción',
  'UPDATE_PAGE': 'Actualizar página',
  'COPYRIGHT': 'Derechos de autor',
  'CHANGE_PASSWORD': 'Cambiar la contraseña',
  'OLD_PASSWORD': 'Contraseña anterior',
  'NEW_PASSWORD': 'Nueva contraseña',
  'CONFIRM_PASSWORD': 'Confirmar Contraseña',
  'ADMIN': 'Administradora',
  'AVAILABLE': 'Disponible',
  'PROFILE': 'Perfil',
  'LOGOUT': 'Cerrar sesión',
  // Erorr Messages
  'CHANGE_PASSWORD_SUCCESSFULLY': 'Cambiar contraseña con éxito',
}