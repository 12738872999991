import {API, BASE_URL} from './config';

export default {
    getCMSList: async () => {
        try {
            const responce = await API.get(`${BASE_URL}/managepages/pages-list`);
            return responce;
        } catch (error) {
            console.log('CMS List', error);
            return error.responce;
        }
    },
    addCMSPage: async (reqBody) => {
        try {
            const responce = await API.post(`${BASE_URL}/managepages/create-page`, reqBody);
            return responce;
        } catch (error) {
            console.log('Add CMS', error);
            return error.responce;
        }
    },
    updateCMSPage: async (reqBody) => {
        try {
            const responce = await API.post(`${BASE_URL}/managepages/update`, reqBody);
            return responce;
        } catch (error) {
            console.log('Update CMS', error);
            return error.responce;
        }
    }
}