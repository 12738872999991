import {API, BASE_URL} from './config';

export default {
    getThematicList: async () => {
        try {
            const response = await API.get(`${BASE_URL}/thematic/thematic-channel-list`);
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },
    addThematic: async (reqBody) => {
        try {   
            const response = await API.post(`${BASE_URL}/thematic/create-thematic`,reqBody);
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },
    updateThematicById: async (reqBody) => {
        try {

            const response = await API.post(`${BASE_URL}/thematic/update-thematic-channel/`,reqBody)
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    }   
}
