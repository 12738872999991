import CryptoJS from "crypto-js";

export const formattedDate = (date) => {
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const d = new Date();
  const today = new Date(date);
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  // const formattedToday = dd + "-" + mm + "-" + yyyy;
  const formattedToday = dd + "-" + monthNames[today.getMonth()] + "-" + yyyy;
  return formattedToday;
};

function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
export const getLoggedData = () => {
  let result = {}
  const secretPass = "XkhZG4fW2t2W0#$";
  const dataUser = localStorage.getItem('data');
  if (dataUser) {
    const bytes = CryptoJS.AES.decrypt(dataUser, secretPass);
    let d = isJsonString(bytes.toString(CryptoJS.enc.Utf8))
    if (d) {
      result = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
  }
  return result;
}