import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logoIcon from '../../assets/images/logo.png';
import authServices from '../../services/auth.services';
import { useFormik, Field } from "formik";
import * as yup from "yup";
import { AuthSchema } from '../../schema/AuthSchema';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getLanguageCode } from '../../helpers/languages';
import CryptoJS from "crypto-js";
import { getLoggedData } from '../../helpers/Helper';


// localStorage.setItem('languageCode', 'sp');

const Login = () => {
    const navigate = useNavigate();
    const [language, setLanguage] = useState(getLanguageCode(localStorage.getItem('languageCode') ? localStorage.getItem('languageCode') : 'en'))
    const [showPassword, setShowPassword] = useState(false);

    const secretPass = "XkhZG4fW2t2W0#$";

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: AuthSchema('login'),
        onSubmit: async (values) => {
            const reqBody = {
                "email": values.email,
                "password": values.password,
            }
            // alert(JSON.stringify(reqBody, null, 2));
            const result = await authServices.loginApi(reqBody);

            console.log('sa', result);

            if (result.status === 200) {
                if (result.data.user.user_type_id === 1) {
                    result.data.auth = true;
                    const endata = CryptoJS.AES.encrypt(
                        JSON.stringify(result.data),
                        secretPass
                    ).toString();
                    localStorage.setItem('data', endata);
                    navigate("/");
                } else {
                    toast.error('Invalid  Credentials', { toastId: 'login_fail', autoClose: 3000 });
                }
            } else {
                toast.error('Invalid  Credentials', { toastId: 'login_fail', autoClose: 3000 });
            }
        },
    });

    useEffect(() => {
        localStorage.setItem('languageCode', 'en')
        let getUserData
        if(localStorage.getItem('data')){
             getUserData = getLoggedData();
        }
        if (getUserData?.auth === true) {
            navigate("/");
        }
    });
    return (
        <div className="bg-login">
            <div className="wrapper">
                <div className="section-authentication-login d-flex align-items-center justify-content-center p-0">
                    <div className="row">
                        <div className="col-12 col-lg-10 mx-auto">
                            <div className="card radius-15 overflow-hidden m-0">
                                <div className="row g-0 align-items-center">
                                    <div className="col-xl-12">
                                        <div className="card-body p-5">
                                            <div className="text-start text-center">
                                                <img src={logoIcon} width={250} alt="" className="mb-4" />
                                                <h2 className="fw-bolder mb-4 text-white">{language.SIGN_IN} <span className='brand-color'>{language.YOUR_ACCOUNT}</span></h2>
                                            </div>
                                            <div className="">
                                                <div className="form-body"  >
                                                    <form className="row g-3" onSubmit={formik.handleSubmit}>
                                                        <div className="col-12">
                                                            <label
                                                                htmlFor="email"
                                                                className="form-label"
                                                            >
                                                                {language.EMAIL_ADDRESS}
                                                            </label>
                                                            <input
                                                                type="email"
                                                                className="form-control"
                                                                id="email"
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.email}
                                                            />
                                                            {formik.touched.email && formik.errors.email ? (
                                                                <small className="text-danger">{formik.errors.email}</small>
                                                            ) : null}
                                                        </div>
                                                        <div className="col-12">
                                                            <label
                                                                htmlFor="password"
                                                                className="form-label"
                                                            >
                                                                {language.ENTER_PASSWORD}
                                                            </label>
                                                            <div className="input-group" id="show_hide_password">
                                                                <input
                                                                    type={showPassword === true ? "text" : "password"}
                                                                    className="form-control border-end-0"
                                                                    id="password"
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    value={formik.values.password}
                                                                />{" "}
                                                                <a
                                                                    href="#"
                                                                    className="input-group-text bg-transparent"
                                                                    onClick={() => setShowPassword((prevState) => !prevState === true)}
                                                                >
                                                                    {showPassword === true ? <i className="bx bx-show text-white" /> : <i className="bx bx-hide text-white" />}

                                                                </a>
                                                            </div>
                                                        </div>
                                                        {formik.touched.password && formik.errors.password ? (
                                                            <small className="text-danger">{formik.errors.password}</small>
                                                        ) : null}
                                                        <div className="col-12 mt-4">
                                                            <div className="d-grid">
                                                                <button type="submit" className="btn btn-primary">
                                                                    <i className="bx bxs-lock-open" />
                                                                    {language.SIGN_IN}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 d-flex align-items-center justify-content-center">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default Login;