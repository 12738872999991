import React from 'react';

import userfff from '../../assets/images/icons/icon-user.svg';
import iconCreator from '../../assets/images/icons/icon-creators.svg';
import iconFree from '../../assets/images/icons/icon-free.svg';
import iconPaid from '../../assets/images/icons/icon-paid.svg';
import iconUnpaid from '../../assets/images/icons/icon-unpaid.svg';
import iconReported from '../../assets/images/icons/icon-reported-user.svg';
import iconBlink from '../../assets/images/icons/icon-blink-videos.svg';
import iconLong from '../../assets/images/icons/icon-long-videos.svg';
import iconShort from '../../assets/images/icons/icon-short-videos.svg';
import iconVideos from '../../assets/images/icons/icon-total-videos.svg';
const DashboardCard = ({ dashboard, language }) => {

    // console.log(language);
    return (
        <div className="row">
            <div className="col-12 col-lg-4">
                <div className="card radius-15 overflow-hidden">
                    <div className="card-body" style={{ position: 'relative' }}>
                        <div className="d-flex">
                            <div className="dashborad-icon">
                                <img src={userfff} alt="" /></div>
                            <div>
                                <h2 className="mb-0 h4 text-white">{dashboard?.totalUsers}</h2>
                                <p className="mb-0 font-weight-bold text-white">{language.PROFILES}</p>
                            </div>
                            <div className="ms-auto align-self-end" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 col-lg-4">
                <div className="card radius-15 overflow-hidden">
                    <div className="card-body" style={{ position: 'relative' }}>
                        <div className="d-flex">
                            <div className="dashborad-icon">
                                <img src={iconCreator} alt="" />
                            </div>
                            <div>
                                <h2 className="mb-0 h4 text-white">{dashboard?.totalContentCreators}</h2>
                                <p className="mb-0 font-weight-bold text-white">{language.CONTENT_CREATORS}</p>
                            </div>
                            <div className="ms-auto align-self-end" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 col-lg-4">
                <div className="card radius-15 overflow-hidden">
                    <div className="card-body" style={{ position: 'relative' }}>
                        <div className="d-flex">
                            <div className="dashborad-icon">
                                <img src={iconFree} alt="" />
                            </div>
                            <div>
                                <h2 className="mb-0 h4 text-white">{dashboard?.totalFreeContentCreators}</h2>
                                <p className="mb-0 font-weight-bold text-white">{language.FREE_TRIAL}</p>
                            </div>
                            <div className="ms-auto align-self-end" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 col-lg-4">
                <div className="card radius-15 overflow-hidden">
                    <div className="card-body" style={{ position: 'relative' }}>
                        <div className="d-flex">
                            <div className="dashborad-icon">
                                <img src={iconFree} alt="" />
                            </div>
                            <div>
                                <h2 className="mb-0 h4 text-white">{dashboard?.totalFreeByAdmin}</h2>
                                <p className="mb-0 font-weight-bold text-white">{language.FREE_BY_ADMIN}</p>
                            </div>
                            <div className="ms-auto align-self-end" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 col-lg-4">
                <div className="card radius-15 overflow-hidden pd-4">
                    <div className="card-body" style={{ position: 'relative' }}>
                        <div className="d-flex">
                            <div className="dashborad-icon">
                                <img src={iconPaid} alt="" />
                            </div>
                            <div>
                                <h2 className="mb-0 h4 text-white">{dashboard?.totalPaidContentCreators}</h2>
                                <p className="mb-0 font-weight-bold text-white">{language.PAID_CONTENT_CREATORS}</p>
                            </div>
                            <div className="ms-auto align-self-end" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 col-lg-4">
                <div className="card radius-15 overflow-hidden pd-4">
                    <div className="card-body" style={{ position: 'relative' }}>
                        <div className="d-flex">
                            <div className="dashborad-icon">
                                <img src={iconUnpaid} alt="" />
                            </div>
                            <div>
                                <h2 className="mb-0 h4 text-white">{dashboard?.totalUnPaidContentCreators}</h2>
                                <p className="mb-0 font-weight-bold text-white">{language.UNPAID_CONTENT_CREATORS}</p>
                            </div>
                            <div className="ms-auto align-self-end" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 col-lg-4">
                <div className="card radius-15 overflow-hidden pd-4">
                    <div className="card-body" style={{ position: 'relative' }}>
                        <div className="d-flex">
                            <div className="dashborad-icon">
                                <img src={iconReported} alt="" />
                            </div>
                            <div>
                                <h2 className="mb-0 h4 text-white">{dashboard?.totalReportedUser}</h2>
                                <p className="mb-0 font-weight-bold text-white">{language.REPORTED_USER}</p>
                            </div>
                            <div className="ms-auto align-self-end" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 col-lg-4">
                <div className="card radius-15 overflow-hidden pd-4">
                    <div className="card-body" style={{ position: 'relative' }}>
                        <div className="d-flex">
                            <div className="dashborad-icon">
                                <img src={iconVideos} alt="" />
                            </div>
                            <div>
                                <h2 className="mb-0 h4 text-white">{dashboard?.totalVideos}</h2>
                                <p className="mb-0 font-weight-bold text-white">{language.TOTAL_VIDEOS}</p>
                            </div>
                            <div className="ms-auto align-self-end" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 col-lg-4">
                <div className="card radius-15 overflow-hidden pd-4">
                    <div className="card-body" style={{ position: 'relative' }}>
                        <div className="d-flex">
                            <div className="dashborad-icon">
                                <img src={iconBlink} alt="" />
                            </div>
                            <div>
                                <h2 className="mb-0 h4 text-white">{dashboard?.totalBlinkVideos}</h2>
                                <p className="mb-0 font-weight-bold text-white">{language.BLINK_VIDEOS}</p>
                            </div>
                            <div className="ms-auto align-self-end" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 col-lg-4">
                <div className="card radius-15 overflow-hidden pd-4">
                    <div className="card-body" style={{ position: 'relative' }}>
                        <div className="d-flex">
                            <div className="dashborad-icon">
                                <img src={iconLong} alt="" />
                            </div>
                            <div>
                                <h2 className="mb-0 h4 text-white">{dashboard?.totalLongVideos}</h2>
                                <p className="mb-0 font-weight-bold text-white">{language.LONG_VIDEOS}</p>
                            </div>
                            <div className="ms-auto align-self-end" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 col-lg-4">
                <div className="card radius-15 overflow-hidden pd-4">
                    <div className="card-body" style={{ position: 'relative' }}>
                        <div className="d-flex">
                            <div className="dashborad-icon">
                                <img src={iconShort} alt="" />
                            </div>
                            <div>
                                <h2 className="mb-0 h4 text-white">{dashboard?.totalShortVideos}</h2>
                                <p className="mb-0 font-weight-bold text-white">{language.SHORT_VIDEOS}</p>
                            </div>
                            <div className="ms-auto align-self-end" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardCard;