export default {
  'SIGN_IN': 'Sign in',
  'YOUR_ACCOUNT': 'your account',
  'EMAIL_ADDRESS': 'Email Address',
  'ENTER_PASSWORD': 'Enter Password',
  'SIGN_IN': 'Sign in',
  'DASHBOARD': 'Dashboard',
  'USERS': 'Users',
  'PROFILES': 'Profiles',
  'CONTENT_CREATORS': 'Content Creators',
  'FREE_TRIAL': 'Free Trial',
  'FREE_BY_ADMIN': 'Free By Admin',
  'PAID_CONTENT_CREATORS': 'Paid Content Creators',
  'UNPAID_CONTENT_CREATORS': 'Unpaid Content Creators',
  'REPORTED_USER': 'Reported User',
  'TOTAL_VIDEOS': 'Total Videos',
  'BLINK_VIDEOS': 'Blink Videos',
  'LONG_VIDEOS': 'Long Videos',
  'SHORT_VIDEOS': 'Short Videos',
  'THEMATIC_CHANNELS': 'Thematic Channels',
  'CHANNELS': 'Channels',
  'VIDEOS': 'Videos',
  'CMS_PAGES': 'CMS Pages',
  'USER_DETAILS': 'User Details',
  'PROFILE_DETAILS': 'Profile Details',
  'NAME': 'Name',
  'NICK_NAME': 'Nick Name',
  'EMAIL': 'Email',
  'COUNTRY': 'Country',
  'BIO': 'Bio',
  'EMAIL_COLLABORATION': 'Email Collaboration',
  'USER_TYPE': 'User Type',
  'TOTAL_FOLLOWERS': 'Total Followers	',
  'TOTAL_FOLLOWING': 'Total Following	',
  'PAYMENT_STATUS': 'Payment Status	',
  'PROMOTION_TITLE_ONE': 'Promotion Title One	',
  'PROMOTION_TITLE_ONE_LINK': 'Promotion Title One Link	',
  'PROMOTION_TITLE_TWO': 'Promotion Title Two	',
  'PROMOTION_TITLE_TWO_LINK': 'Promotion Title Two Link	',
  'PROMOTION_TITLE_THREE': 'Promotion Title Three	',
  'PROMOTION_TITLE_THREE_LINK': 'Promotion Title Three Link	',
  'FREE_TRIAL': 'Free Trial',
  'PAID': 'Paid',
  'USER': 'User',
  'CREATOR': 'Creator',
  'SRNO': 'SR.NO.	',
  'STATUS': 'STATUS',
  'LOCKONOFF': 'Lock On/Off',
  'ACTION': 'ACTION',
  'RECORD_NOT_FOUND': 'Record Not Found',
  'SEARCH': 'Search',
  'BACK_TO_LIST': 'Back to List',
  'THEMATIC_CHANNEL_NAME': 'Thematic channel name',
  'ADD': 'Add',
  'ADD_THEMATIC_CHANNEL': 'Add Thematic Channel',
  'CLOSE': 'Close',
  'SAVE': 'Save',
  'UPDATE': 'Update',
  'UPDATE_THEMATIC_CHANNEL': 'Update Thematic Channel',
  'CHANNEL_NAME': 'Channel Name',
  'ADD_CHANNEL': 'Add Channel',
  'UPDATE_CHANNEL':'Update Channel',
  'LIKE': 'Like',
  'SHARE': 'Share',
  'DATE': 'Date',
  'COMMENT' : 'Comment',
  'FAVOURITE': 'Favourite',
  'VIDEO_ID': 'Video Id',
  'VIEW_VIDEO' : 'View Video',
  'REPORT_BY': 'Report By',
  'REPORT_TO': 'Report To',
  'REPORT_MESSAGE': 'Report Message',
  'REPORT_DATE': 'Report Date',
  'URL': 'URL',
  'ADD_PAGE': 'Add Page',
  'DESCRIPTION': 'Description',
  'UPDATE_PAGE': 'Update Page',
  'COPYRIGHT': 'Copyright',
  'CHANGE_PASSWORD': 'Change Password',
  'OLD_PASSWORD': 'Old Password',
  'NEW_PASSWORD': 'New Password',
  'CONFIRM_PASSWORD': 'Confirm Password',
  'ADMIN': 'Admin',
  'AVAILABLE': 'Available',
  'PROFILE': 'Profile',
  'LOGOUT': 'Logout',
  'MESSAGES':'Messages',
  // Erorr Messages
  'CHANGE_PASSWORD_SUCCESSFULLY': 'Change password successfully',


}
