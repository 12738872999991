import React, { useState } from 'react';
import PageBreadcrumb from '../../components/Comman/PageBreadcrumb';
import { getLanguageCode } from '../../helpers/languages';
import MessagesForm from '../../components/Messages/MessagesForm';

const Messages = () => {
    const [language, setLanguage] = useState(getLanguageCode(localStorage.getItem('languageCode') ? localStorage.getItem('languageCode') : 'en'))


    return (
        <>
            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <PageBreadcrumb pageTitle={language.MESSAGES} />
            </div>
            <div className='row'>
                <div className='col-md-4'>
                    <div className='card'>
                        <div className='card-body'>
                            <MessagesForm language={language} />
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default Messages;