import {API, BASE_URL} from './config';

export default {
    getChannelsData: async () => {
        try {
            const response = await API.get(`${BASE_URL}/video/chennel-list`);
            return response;
        } catch (error) {
            console.log(error);
            return error.response;
        }
    },
    addChannel: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/channel/create`, reqBody);
            return response;
        } catch (error) {
            console.log('Add Channel', error);
            return error.response;
        }
    },
    updateChannelById: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/channel/update`, reqBody);
            return response;
        } catch (error) {
            console.log('Update Channel', error)
            return error.response;
        }
    },
    deleteChannelById: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/channel/delete`, reqBody);
            return response;
        } catch (error) {
            console.log('Delete Channel', error);
            return error.response;
        }
    }
}