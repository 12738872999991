import {API, BASE_URL} from './config';

export default {
    getAllUsers: async ()=>{
        try {
            const response = await API.get(`${BASE_URL}/user/user-list`);
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },
    getDashboardData: async () => {
        try {
            const response = await API.get(`${BASE_URL}/user/dashboard-counting`);
            return response;
        } catch (error) {
            console.log('Dashboard Data', error);
            return error.response;
        }
    },
    userStatus: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/user/update-user-status`,reqBody);
            return response;
        } catch (error) {
            console.log('User Status', error);
            return error.response;
        }
    },
    ChnagePassword: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/user/update-password`, reqBody);
            return response;
        } catch (error) {
            console.log('Update User', error);
            return error.response;
        }
    },
    deleteUser: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/user/delete-profile-by-admin`, reqBody);
            return response;    
        } catch (error) {
            console.log('Detele User', error);
            return error.response;
        }
    },
    createUser: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/user/register-by-admin`, reqBody);
            return response;    
        } catch (error) {
            console.log('Add User', error);
            return error.response;
        }
    },
    updateUser: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/user/user-profile-by-admin`, reqBody);
            return response;    
        } catch (error) {
            console.log('Update User', error);
            return error.response;
        }
    },
    sendMsg: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/user/send-message-by-admin`, reqBody)
            return response;
        } catch (error) {
            console.log('send Msg', error);
            return error.response;
        }
    },
    makeFree: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/user/make-free-profile`, reqBody)
            return response;
        } catch (error) {
            console.log('', error);
            return error.response;
        }
    }


}