import axios from "axios";

const API = axios;
// const BASE_URL='http://localhost:4001';
// const BASE_URL='http://192.168.0.122:4001'; ///localhost
// const BASE_URL='http://103.15.67.180:4006';
// const BASE_URL='https://youuup.com/youuupapi';
// const BASE_URL='https://youuup.es/test_youuup_api';
const BASE_URL= 'https://youuup.es/mobile-api'

export  {
    API,
    BASE_URL
}