import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import TextError from '../../helpers/FormikHelper/TextError';
import {getLoggedData} from '../../helpers/Helper';
import userServices from '../../services/user.services';
import { toast } from 'react-toastify';
import { FieldSchema } from '../../schema/FieldSchema';

const MessagesForm = ({ language }) => {
    const userDetail = getLoggedData();
    const initialValues = {
        user_type_id: '',
        title: '',
        body: '',       
    }

    const onSubmit = async (values, { resetForm }) => {
        const reqBody = {
            user_type_id: Number(values.user_type_id),
            title: values.title,
            body: values.body,
            admin_id: userDetail.user.id
        }
        let result = await userServices.sendMsg(reqBody);
        if(result.data.status === true){
            toast.success('Message send successfully', {toastId : 'send_success', autoClose: 3000 });
            resetForm()
        } else {
            toast.success(result.data.message , { toastId: 'registration_fail', autoClose: 3000 });
        }
    }

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={FieldSchema('MESSAGES')}
                onSubmit={onSubmit}
                enableReinitialize
            >
                {() => (<Form>
                    <div className="row" >
                        <div className="col-12 mb-3">
                            <label htmlFor="user_type_id" className="form-label">
                                User Type
                            </label>
                            <Field as='select' className='form-select' id="user_type_id" name="user_type_id" >
                                <option disabled value="">Select User Type</option>
                                <option value='0'>All  </option>
                                <option value="2">All Profiles</option>
                                <option value="3">All Creators</option>
                            </Field>
                            <ErrorMessage name='user_type_id' component={TextError} />
                        </div>
                        <div className="col-12 mb-3">
                            <label htmlFor="title" className="form-label">
                               Title  
                            </label>
                            <Field type="text" className="form-control" id="title" name='title'
                                />
                            <ErrorMessage name='title' component={TextError} />
                        </div>
                        <div className="col-12 mb-3">
                            <label htmlFor="body" className="form-label">
                                Message
                            </label>
                            <Field as="textarea" type="text" className="form-control" id="body" name='body' style={{'min-height': '200px'}}
                                />
                            <ErrorMessage name='body' component={TextError} />
                        </div>
                    </div>
                    <div className='text-end'>
                        <button type="submit" className="btn btn-primary btn-sm">
                            Send
                        </button>
                    </div>


                </Form>
                )}
            </Formik>
        </>
    );
};

export default MessagesForm;