import React from 'react';
import { Label, Modal, ModalBody, ModalFooter, Table } from 'reactstrap';
import { BASE_URL } from '../../services/config';
import iconUserImg from '../../assets/images/icons/icon-user-img.png';
import { formattedDate, getLoggedData } from '../../helpers/Helper';
import userServices from '../../services/user.services';
import { useState } from 'react';



const UserDetails = ({ selectedRow, language, getUsers }) => {

    const [modal, setModal] = React.useState(false);
    const toggle = () => setModal(!modal);
    const getUserData = getLoggedData();
    const [btnFree, setBtnFree] = useState(selectedRow.payment_status);
    
    const makeFree = async () => {
        const reqBody = {
            user_id: selectedRow?.id,
            admin_id: getUserData.user.id,
        }
        let result = await userServices.makeFree(reqBody);
        if(result.data.status === true){
           toggle()
           setBtnFree(2)
           getUsers();
        }
    }

    return (
        <div className='row'>
            <div className='col-md-6'>
                <div className='card'>
                    <div className='card-body'>
                        <div className='row'>
                            <div className='col-md-4 '>
                                <div className='position-relative m-auto' style={{ width: 'fit-content' }}>
                                    {selectedRow?.profile_photo === null || selectedRow?.profile_photo === '' ?
                                        <img src={iconUserImg} alt="" className='rounded-circle' width={'140px'} height={'140px'} /> :
                                        <img src={`${BASE_URL}/${selectedRow?.profile_photo}`} alt="" className='rounded-circle' width={'140px'} height={'140px'} />}
                                    <div className={`user-active ${selectedRow?.status === 1 && 'user-actives'} `}></div>
                                </div>
                            </div>
                            <div className='col-md-8'>
                                <Table className='mb-0'>
                                    <tbody>
                                        <tr>
                                            <td className='p-1 border-bottom-0'>{language.NAME} :- <span className='ps-2 brand-color'>{selectedRow?.name}</span></td>
                                        </tr>
                                        <tr>
                                            <td className='p-1 py-0 border-bottom-0'>{language.NICK_NAME} :- <span className='ps-2 brand-color'>{selectedRow?.nick_name}</span></td>
                                        </tr>
                                        <tr>
                                            <td className='p-1 border-bottom-0'>{language.EMAIL} :- <span className='ps-2 brand-color'>{selectedRow?.email}</span></td>
                                        </tr>
                                        <tr>
                                            <td className='p-1 py-0 border-bottom-0'>{language.COUNTRY} :- <span className='ps-2 brand-color'>{selectedRow?.country}</span></td>
                                        </tr>

                                    </tbody>
                                </Table>
                                <nav className="nav align-items-center mt-1">
                                    <a className="nav-link p-1 pb-0" target='_blank' href={selectedRow?.facebook_url}>
                                        <i className='lni lni-facebook font-16 brand-color'></i>
                                    </a>
                                    <a className="nav-link p-1 pb-0 px-2" target='_blank' href={selectedRow?.instagram_url}>
                                        <i className='lni lni-instagram font-16 brand-color'></i>
                                    </a>
                                    <a className="nav-link p-1 pb-0" target='_blank' href={selectedRow?.youtube_url}>
                                        <i className='lni lni-youtube font-16 brand-color'></i>
                                    </a>
                                    <a className="nav-link p-1 pb-0 px-2" target='_blank' href={selectedRow?.website_url}>
                                        <i className='bx bx-globe font-16 brand-color'></i>
                                    </a>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                {selectedRow?.user_type_id === 3 &&
                    <div className='card mb-0'>
                        <div className='card-body'>
                            <Table>
                                <tbody>
                                    <tr>
                                        <td>{language.PAYMENT_STATUS}</td>
                                        <td>
                                            {btnFree === 0 ? `${language.FREE_TRIAL}` : btnFree === 1 ? `${language.PAID}` : 'Free By Admin'}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Exp. Date</td>
                                        <td className='text-danger'>{formattedDate(selectedRow?.payment_expires_date)}</td>
                                    </tr>
                                    {btnFree != 2 && 
                                    <tr>
                                        <td>Make Free</td>
                                        <td>
                                            <button className='btn btn-primary btn-sm'
                                                onClick={() => {
                                                    toggle();
                                                }}
                                            >Make a Free</button>
                                        </td>
                                    </tr> }
                                </tbody>
                            </Table>
                        </div>
                    </div>}
            </div>
            <div className='col-md-6'>
                <div className='card mb-0'>
                    <div className='card-body'>
                        <Table>
                            <tbody>
                                <tr>
                                    <td>{language.BIO}</td>
                                    <td>{selectedRow?.bio}</td>
                                </tr>
                                <tr>
                                    <td>{language.EMAIL_COLLABORATION}</td>
                                    <td>{selectedRow?.email_collaboration}</td>
                                </tr>
                                <tr>
                                    <td>{language.USER_TYPE}</td>
                                    <td>{selectedRow?.user_type_id === 2 ? `${language.USER}` : `${language.CREATOR}`}</td>
                                </tr>
                                <tr>
                                    <td>{language.TOTAL_FOLLOWERS}</td>
                                    <td>{selectedRow?.followers_count}</td>
                                </tr>
                                <tr>
                                    <td>{language.TOTAL_FOLLOWING}</td>
                                    <td>{selectedRow?.following_count}</td>
                                </tr>
                                <tr>
                                    <td>{language.PROMOTION_TITLE_ONE}</td>
                                    <td>{selectedRow?.promotion_title_one}</td>
                                </tr>
                                <tr>
                                    <td>{language.PROMOTION_TITLE_ONE_LINK}</td>
                                    <td>{selectedRow?.promotion_title_one_link}</td>
                                </tr>
                                <tr>
                                    <td>{language.PROMOTION_TITLE_TWO}</td>
                                    <td>{selectedRow?.promotion_title_two}</td>
                                </tr>
                                <tr>
                                    <td>{language.PROMOTION_TITLE_TWO_LINK}</td>
                                    <td>{selectedRow?.promotion_title_two_link}</td>
                                </tr>
                                <tr>
                                    <td>{language.PROMOTION_TITLE_THREE}</td>
                                    <td>{selectedRow?.promotion_title_three}</td>
                                </tr>
                                <tr>
                                    <td>{language.PROMOTION_TITLE_THREE_LINK}</td>
                                    <td>{selectedRow?.promotion_title_three_link}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>

            <Modal isOpen={modal} toggle={toggle} size="sm" centered>
                <ModalBody className="text-center rounded-top">
                    <span className="h1 d-block text-danger">
                        <i className="lni lni-question-circle"></i></span>
                    <p className="h5 text-white">Do you want to apporve</p>
                </ModalBody>
                <ModalFooter className="border-0 justify-content-center pt-0 pb-4">
                    <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={makeFree}
                    >
                        Yes
                    </button>
                    <button
                        type="button"
                        className="btn btn-secondary btn-sm"
                        onClick={() => {
                            toggle()
                        }}
                    >
                        No
                    </button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default UserDetails;