import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import { ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { toast } from 'react-toastify';
import channelsServices from '../../services/channels.services';
import { FieldSchema } from '../../schema/FieldSchema';
import TextError from '../../helpers/FormikHelper/TextError';

const ChannelForm = ({ toggle, isAddMode, getChannelsList, selectedRow, getThematicChannelsList, language }) => {
    const initialValues = {
        thematic_channel_id: '',
        channel_name: ''
    }

    const savedValues = {
        thematic_channel_id: selectedRow?.thematic_channel_id,
        channel_name: selectedRow?.channel_name
    }

    const onSubmit = async (values) => {
        const reqBody = {
            thematic_channel_id: values.thematic_channel_id,
            channel_name: values.channel_name
        }
        if (isAddMode === 'Add') {
            let result = await channelsServices.addChannel(reqBody);
            if (result.data.status === true) {
                toggle()
                getChannelsList()
                toast.success('Add channels successfully', { toastId: 'registration_success', autoClose: 3000 });
            } else {
                toast.error(result.data.message, { toastId: 'registration_fail', autoClose: 3000 });
            }

        } else {
            const reqBody = {
                channel_id: selectedRow?.id,
                thematic_channel_id: values.thematic_channel_id,
                channel_name: values.channel_name
            }

            console.log('Hellosdd', reqBody);
            const result = await channelsServices.updateChannelById(reqBody);
            if (result.data.status === true) {
                toggle()
                getChannelsList()
                toast.success('Update channels successfully', { toastId: 'update_success', autoClose: 3000 });
            } else {
                toast.error(result.data.message, { toastId: 'update_fail', autoClose: 3000 });

            }
        }
    }

   return (
        <React.Fragment>
            <Formik
                initialValues={isAddMode === 'Add' ? initialValues : savedValues}
                validationSchema={FieldSchema('CHANNEL')}
                onSubmit={onSubmit}
                enableReinitialize
            >
                {({ setFieldValue, values }) => (<Form>
                    <ModalHeader toggle={toggle} className='border-0 pb-0'>
                        <div className="card-title d-flex align-items-center mb-0">
                            <h5 className="mb-0 text-primary">{isAddMode === 'Add' ? `${language.ADD_CHANNEL}` : `${language.UPDATE_CHANNEL}`}</h5>
                        </div>
                    </ModalHeader>
                    <ModalBody className="">
                        <div className="row" >
                            <div className="col-md-12 mb-3">
                                <label htmlFor="thematic_channel_id" className="form-label">
                                    {language.THEMATIC_CHANNEL_NAME}
                                </label>
                                <Field as='select' className='form-select' id="thematic_channel_id" name="thematic_channel_id" >
                                    <option disabled value="">Select Thematic CHannel</option>
                                    {
                                        getThematicChannelsList.map((e, key) => {
                                            return <option key={key} value={e.id}>{e.thematic_channel_name}</option>;
                                        })
                                    }
                                </Field>
                                <ErrorMessage name="thematic_channel_id" component={TextError} />
                            </div>
                            <div className="col-12">
                                <label htmlFor="channel_name" className="form-label">
                                    {language.CHANNEL_NAME}                                   
                                </label>
                                <Field type="text" className="form-control" id="channel_name" name='channel_name'
                                />
                                <ErrorMessage name='channel_name' component={TextError} />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter className='border-0'>
                        <button
                            type="button"
                            className="btn btn-secondary btn-sm"
                            onClick={toggle}
                        >
                          {language.CLOSE}
                        </button>
                        <button type="submit" className="btn btn-primary btn-sm">
                            {isAddMode === "Add" ? `${language.SAVE}` : `${language.UPDATE}`}
                        </button>
                    </ModalFooter>
                </Form>
                )}
            </Formik>
        </React.Fragment>
    );
};

export default ChannelForm;