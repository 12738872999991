import {API, BASE_URL} from './config';

export default {
    allVideos: async (reqBody) => {
        try {
            const responce = await API.post(`${BASE_URL}/video/get-videos-for-web`, reqBody);
            return responce;
        } catch (error) {
            console.log('Get BLink', error);
            return error.responce;
        }
    },
    deleteVideo: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/video/delete-video-by-admin`, reqBody);
            return response;    
        } catch (error) {
            console.log('Detele Video', error);
            return error.response;
        }
    }
}