import React from 'react';
import { Row, Col, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { useMemo } from "react";
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
const { SearchBar } = Search;
const UserList = ({ users, setSelectedRow, setUserDetails, handleUserStatus, language, getUsers, deleteById, setIsAddMode, setModal }) => {

    let dataList = users.map((item, index) => {
        const freeAdmin = item.payment_status;
        const userType = item.user_type_id;

        item.srNo = index + 1
        item.free_by_admin = item.user_type_id === 3 ? item.payment_status === 0 ? `${language.FREE_TRIAL}` : item.payment_status === 1 ?  `${language.PAID}` : 'Free By Admin' : '-';
        item.userType = item.user_type_id === 2 ? `${language.USER}` : `${language.CREATOR}`

        console.log('asd', item);
        return item
    })
    const tblColumn = useMemo(() => [
        {
            dataField: "srNo",
            text: `${language.SRNO}`,
        },
        {
            dataField: "name",
            text: `${language.NAME}`,
            sort: true,
        },
        {
            dataField: "nick_name",
            text: `${language.NICK_NAME}`,
            sort: true,
        },
        {
            dataField: "email",
            text: `${language.EMAIL}`,
            sort: true,
        },
        {
            dataField: "userType",
            text: `${language.USER_TYPE}`,
            sort: true,
        },
        {
            dataField: "country",
            text: `${language.COUNTRY}`,
            sort: true,
        },
        {
            dataField: "free_by_admin",
            text: `${language.PAYMENT_STATUS}`,
            sort: true,
        },
        {
            dataField: "otp_verify",
            text: 'OTP Verify',
            sort: true,
            formatter: (value, row) => {
                return (
                    <>
                        {row.otp_verify === 1 ? 'Yes' : 'No'}
                    </>
                )
            }
        },
        {
            dataField: "status",
            text: `${language.LOCKONOFF}`,
            sort: true,
            formatter: (value, row) => {
                return (
                    <>
                        <BootstrapSwitchButton
                            checked={row.status === 1 ? true : false}
                            onstyle="primary"
                            offstyle="secondary"
                            onChange={(checked) => {
                                console.log('Checked ', checked)
                                handleUserStatus(row.id, checked ? 1 : 0)
                            }}
                        />
                    </>
                )
            },
        },
        {
            dataField: "",
            text: `${language.ACTION}`,
            formatter: (value, row) => {
                return (
                    <>
                        <UncontrolledDropdown>
                            <DropdownToggle className="card-drop" tag="a">
                                <i className="lni lni-more-alt font-size-16" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end action_drop_down">
                                <DropdownItem
                                    onClick={() => {
                                        setSelectedRow(row);
                                        setUserDetails(true);
                                    }}
                                >
                                    <i className="lni lni-eye font-size-16 text-primary me-1" />
                                    View
                                </DropdownItem>
                                <DropdownItem
                                    onClick={() => {
                                        setSelectedRow(row);
                                        setIsAddMode('Edit');
                                        setModal(true)
                                    }}
                                >
                                    <i className="lni lni-pencil font-size-16 text-success me-1" />
                                    Edit
                                </DropdownItem>
                                <DropdownItem
                                    onClick={() => {
                                        getUsers();
                                        deleteById(row.id);
                                    }}
                                >
                                    <i className="lni lni-trash font-size-16 text-danger me-1" />
                                    Delete
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </>
                );
            },
        },
    ]);

    const defaultSorted = [
        {
            dataField: "_id",
            order: "desc",
        },
    ];
    const pzipcodeOptions = {
        sizePerPzipcode: 10,
        totalSize: dataList.length, // replace later with size(customers),
        custom: true,
    };

    return (
        <>
            <PaginationProvider
                pagination={paginationFactory(pzipcodeOptions)}
                keyField="id"
                columns={tblColumn}
                data={dataList}
            >
                {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider keyField="_id" columns={tblColumn} data={dataList} search>
                        {(toolkitProps) => (
                            <React.Fragment>
                                <Row className="mb-2" style={{ display: "none" }}>
                                    <Col md="4">
                                        <div className="search-box me-2 mb-2 d-inline-block">
                                            <div className="status-relative"></div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl="12">
                                        <div className="text-right col-md-3 offset-md-9 mb-2">
                                            <label className="pe-3 text-white">{language.SEARCH}</label>
                                            <SearchBar
                                                className="w-100"
                                                {...toolkitProps.searchProps}
                                            />
                                        </div>
                                        <div className="table-responsive">
                                            <BootstrapTable
                                                noDataIndication={
                                                    <h6 className="d-flex justify-content-center">
                                                        {language.RECORD_NOT_FOUND}
                                                    </h6>
                                                }
                                                keyField={"id"}
                                                responsive
                                                bordered={false}
                                                striped={false}
                                                defaultSorted={defaultSorted}
                                                classes={
                                                    "table table-striped align-middle table-nowrap"
                                                }
                                                headerWrapperClasses={"thead-light"}
                                                {...toolkitProps.baseProps}
                                                {...paginationTableProps}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                {dataList.length > 0 && (
                                    <Row className="align-items-md-center mt-30">
                                        <Col className="inner-custom-pagination mt-2 d-flex">
                                            <div className="d-inline">
                                                <SizePerPageDropdownStandalone {...paginationProps} />
                                            </div>
                                            <div className="text-md-right ms-auto">
                                                <PaginationListStandalone {...paginationProps} />
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                            </React.Fragment>
                        )}
                    </ToolkitProvider>
                )}
            </PaginationProvider>
        </>
    );
};

export default UserList;