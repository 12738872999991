import React, { useEffect, useState } from 'react';
import reportServices from '../../services/report.services';
import ReportUserList from '../../components/ReportUser/ReportUserList';
import PageBreadcrumb from '../../components/Comman/PageBreadcrumb';
import { getLanguageCode } from '../../helpers/languages';

const ReportedUser = () => {

    const [reportList, setReportList] = useState([]);
    const [language, setLanguage] = useState(getLanguageCode(localStorage.getItem('languageCode')?localStorage.getItem('languageCode'):'en'))

    const getReportUser = async () => {
        let result = await reportServices.getReport();
        result = result.data.result;
        setReportList(result)
    }

    useEffect(() => {
        getReportUser();
    }, []);


    return (
        <>
            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <PageBreadcrumb pageTitle={language.REPORTED_USER}/>
            </div>
            <div className='card'>
                <div className='card-body'>
                    <ReportUserList reportList={reportList} language={language} />
                </div>
            </div>
        </>
    );
};

export default ReportedUser;