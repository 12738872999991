import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { FieldSchema } from '../../schema/FieldSchema';
import TextError from '../../helpers/FormikHelper/TextError';
import CryptoJS from "crypto-js";
import userServices from '../../services/user.services';
import { toast } from 'react-toastify';
import { getLoggedData } from '../../helpers/Helper';


const ChangePassword = ({ language }) => {
    const initialValues = {
        old_password: '',
        new_password: '',
        new_confirm_password: ''
    }

    const onSubmit = async (values) => {
        const getUserData = getLoggedData();

        const reqBody = {
            email: getUserData.user.email,
            old_password: values.old_password,
            new_password: values.new_password,
        }
        let result = await userServices.ChnagePassword(reqBody);
        if (result.data.status === true) {
            toast.success(language.CHANGE_PASSWORD_SUCCESSFULLY, { toastId: 'password_change', autoClose: 3000 });
        } else {
            toast.error(result.data.message, { toastId: 'password_change_fail', autoClose: 3000 });
        }
    }

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={FieldSchema('CHANGE_PASSWORD')}
                onSubmit={onSubmit}
                enableReinitialize
            >
                {() => (
                    <Form>
                        <div className="row" >
                            <div className="col-12 mb-3">
                                <label htmlFor="old_password" className="form-label">
                                    {language.OLD_PASSWORD}
                                </label>
                                <Field type="password" className="form-control" id="old_password" name='old_password'
                                />
                                <ErrorMessage name='old_password' component={TextError} />
                            </div>
                            <div className="col-12 mb-3">
                                <label htmlFor="new_password" className="form-label">
                                    {language.NEW_PASSWORD}
                                </label>
                                <Field type="password" className="form-control" id="new_password" name='new_password'
                                />
                                <ErrorMessage name='new_password' component={TextError} />
                            </div>
                            <div className="col-12 mb-3">
                                <label htmlFor="new_confirm_password" className="form-label">
                                    {language.CONFIRM_PASSWORD}
                                </label>
                                <Field type="password" className="form-control" id="new_confirm_password" name='new_confirm_password'
                                />
                                <ErrorMessage name='new_confirm_password' component={TextError} />
                            </div>
                        </div>
                        <div className='text-end'>
                            <button type="submit" className="btn btn-primary btn-sm ">{language.UPDATE}</button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default ChangePassword;