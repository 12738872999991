import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import iconLogo from '../../assets/images/logo-icon.png';
import logo from '../../assets/images/logo.png'

import centericon from '../../assets/images/icons/center.svg';
import centericonHover from '../../assets/images/icons/centerfff.svg';
import cms from '../../assets/images/icons/cms.svg';
import cmsHover from '../../assets/images/icons/cmsfff.svg';
import user from '../../assets/images/icons/user.svg';
import userfff from '../../assets/images/icons/userfff.svg';
import iconReporteduser from '../../assets/images/icons/icon-reprtedUser.svg';
import iconReporteduserfff from '../../assets/images/icons/icon-reprtedUserfff.svg';
import iconChannel from '../../assets/images/icons/icon-channel.svg';
import iconChannelfff from '../../assets/images/icons/icon-channelsfff.svg';
import iconcms from '../../assets/images/icons/icon-cms.svg';
import iconcmsfff from '../../assets/images/icons/icon-cmsfff.svg';
import iconvideo from '../../assets/images/icons/icon-video.svg';
import iconvideofff from '../../assets/images/icons/icon-videofff.svg';
import iconmsg from '../../assets/images/icons/icon-msg.svg';
import iconmsgfff from '../../assets/images/icons/icon-msgfff.svg';
import iconThematicChannel from '../../assets/images/icons/icon-thematic-channels.svg';
import iconThematicChannelfff from '../../assets/images/icons/icon-thematic-channelsfff.svg';
import { getLanguageCode } from "../../helpers/languages";
import { getLoggedData } from '../../helpers/Helper';


const SideBar = ({ menuIconOnClick, handleMouseOver, handleMouseOut }) => {

    const navigate = useNavigate();

    const [isActive, setIsActive] = useState('');
    // const [cmsMenuActive, setCmsMenuActive] = useState(false)
    const [language, setLanguage] = useState(getLanguageCode(localStorage.getItem('languageCode') ? localStorage.getItem('languageCode') : 'en'))
    const onClick = (value) => {
        setIsActive(value)
    };
    // const onClickSubMenu = (value) => {
    //     setIsActive(value)

    // };
    // const onCLickCms = () => {
    //     setCmsMenuActive((prevState) => prevState ? false : true)
    // }
    useEffect(() => {
        setIsActive(window.location.pathname)
        const addClassBody = document.body.classList.add('dashboard-BG')
    }, [isActive])


    return (
        <>
            <div className="sidebar-wrapper admin-sidebar" data-simplebar="true"
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
            >
                <div className="sidebar-header">
                    <div className="">
                        <img src={iconLogo} className="logo-icon-2" alt="" />
                    </div>
                    <div>
                        <h4 className="logo-text"><img src={logo} className="side_logo" alt="" /></h4>
                    </div>
                    <Link to="#" className="toggle-btn ms-auto">
                        <i className="bx bx-menu" onClick={menuIconOnClick} />
                    </Link>
                </div>

                <ul className="metismenu" id="menu">
                    <li className={isActive === '/' ? 'mm-active' : ''}>
                        <Link to='/' onClick={onClick}>
                            <div className="parent-icon">
                                <img src={cms} />
                                <img src={cmsHover} />
                            </div>
                            <div className="menu-title">{language.DASHBOARD}</div>
                        </Link>
                    </li>
                    <li className={isActive === '/profiles' || isActive === '/profile' ? 'mm-active' : ''}>
                        <Link to='/profiles' onClick={onClick}>
                            <div className="parent-icon">
                                <img src={user} />
                                <img src={userfff} />
                            </div>
                            <div className="menu-title">{language.PROFILES}</div>
                        </Link>
                    </li>
                    <li className={isActive === '/thematic-channels' ? 'mm-active' : ''}>
                        <Link to='/thematic-channels' onClick={onClick}>
                            <div className="parent-icon">
                                <img src={iconThematicChannel} />
                                <img src={iconThematicChannelfff} />
                            </div>
                            <div className="menu-title">{language.THEMATIC_CHANNELS}</div>
                        </Link>
                    </li>
                    <li className={isActive === '/channels' ? 'mm-active' : ''}>
                        <Link to='/channels' onClick={onClick}>
                            <div className="parent-icon">
                                <img src={iconChannel} />
                                <img src={iconChannelfff} />
                            </div>
                            <div className="menu-title">{language.CHANNELS}</div>
                        </Link>
                    </li>
                    <li className={isActive === '/videos' ? 'mm-active' : ''}>
                        <Link to='/videos' onClick={onClick}>
                            <div className="parent-icon">
                                <img src={iconvideo} />
                                <img src={iconvideofff} />
                            </div>
                            <div className="menu-title">{language.VIDEOS}</div>
                        </Link>
                    </li>
                    <li className={isActive === '/reported-user' ? 'mm-active' : ''}>
                        <Link to='/reported-user' onClick={onClick}>
                            <div className="parent-icon">
                                <img src={iconReporteduser} />
                                <img src={iconReporteduserfff} />
                            </div>
                            <div className="menu-title">{language.REPORTED_USER}</div>
                        </Link>
                    </li>
                    <li className={isActive === '/messages' ? 'mm-active' : ''}>
                        <Link to='/messages' onClick={onClick}>
                            <div className="parent-icon">
                                <img src={iconmsg} />
                                <img src={iconmsgfff} />
                            </div>
                            <div className="menu-title">{language.MESSAGES}</div>
                        </Link>
                    </li>
                    <li className={isActive === '/cms-pages' ? 'mm-active' : ''}>
                        <Link to='/cms-pages' onClick={onClick}>
                            <div className="parent-icon">
                                <img src={iconcms} />
                                <img src={iconcmsfff} />
                            </div>
                            <div className="menu-title">{language.CMS_PAGES}</div>
                        </Link>
                    </li>
                    {/* <li className="menu-label">CMS</li>
                    <li className={`${cmsMenuActive === true ? 'mm-active' : ''} `}>
                        <a className="has-arrow" href="#" onClick={onCLickCms}>
                            <div className="parent-icon icon-color-11">
                                <img src={cms} />
                                <img src={cmsHover} />
                            </div>
                            <div className="menu-title">CMS</div>
                        </a>
                        <ul className={`mm-collapse ${cmsMenuActive === true ? 'mm-show' : ''} `}>
                            <li className={isActive === '/about-us' ? 'mm-active' : ''}>
                                {" "}
                                <Link to='/about-us' onClick={onClick}>
                                    <i className="bx bx-right-arrow-alt" />
                                    About us
                                </Link>
                            </li>
                            <li className={isActive === '/contact-us' ? 'mm-active' : ''}>
                                {" "}
                                <Link to='/contact-us' onClick={onClick}>
                                    <i className="bx bx-right-arrow-alt" />
                                    Contact Us
                                </Link>
                            </li>
                            <li className={isActive === '/privacy-policy' ? 'mm-active' : ''}>
                                {" "}
                                <Link to='/privacy-policy' onClick={onClick}>
                                    <i className="bx bx-right-arrow-alt" />
                                    Privacy Policy
                                </Link>
                            </li>
                            <li className={isActive === '/terms-use' ? 'mm-active' : ''}>
                                {" "}
                                <Link to='/terms-use' onClick={onClick}>
                                    <i className="bx bx-right-arrow-alt" />
                                    Terms of Use
                                </Link>
                            </li>

                            <li className={isActive === '/cookie-policy' ? 'mm-active' : ''}>
                                {" "}
                                <Link to='/cookie-policy' onClick={onClick}>
                                    <i className="bx bx-right-arrow-alt" />
                                    Cookie Policy
                                </Link>
                            </li>
                            <li className={isActive === '/social' ? 'mm-active' : ''}>
                                {" "}
                                <Link to='/social' onClick={onClick}>
                                    <i className="bx bx-right-arrow-alt" />
                                    Social and Tax Information
                                </Link>
                            </li>
                            <li className={isActive === '/membership' ? 'mm-active' : ''}>
                                {" "}
                                <Link to='/membership' onClick={onClick}>
                                    <i className="bx bx-right-arrow-alt" />
                                    Membership
                                </Link>
                            </li>
                        </ul>
                    </li> */}
                </ul>
            </div>

        </>
    )
}

export default SideBar;
