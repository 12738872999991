import React, { useEffect, useState } from "react";
import UserList from "../../components/Users/UserList";
import { Modal, } from "reactstrap";
import userServices from "../../services/user.services";
import UserDetails from "../../components/Users/UserDetails";
import PageBreadcrumb from '../../components/Comman/PageBreadcrumb';
import { toast } from "react-toastify";
import { getLanguageCode } from "../../helpers/languages";
import UserForm from "../../components/Users/UserForm";

const User = () => {
    const [selectedRow, setSelectedRow] = useState();
    const [modal, setModal] = React.useState(false);
    const toggle = () => setModal(!modal);
    const [isAddMode, setIsAddMode] = useState('Add');
    const [users, setUsers] = useState([]);
    const [userDetails, setUserDetails] = useState(false);
    const [language, setLanguage] = useState(getLanguageCode(localStorage.getItem('languageCode') ? localStorage.getItem('languageCode') : 'en'))

    const getUsers = async () => {
        let result = await userServices.getAllUsers();
        result = result.data.result;
        setUsers(result);
    }

    const handleUserStatus = async (id, status) => {
        let result = await userServices.userStatus({ user_id: id, status: status });
        if (result.data.status === true) {
            getUsers();
            toast.success('User status updated successfully', { toastId: 'update_success', autoClose: 3000 });
        } else {
            toast.error(result.data.message, { toastId: 'update_fail', autoClose: 3000 });
        }
    }

    const deleteById = async (id) => {       
        const result = await userServices.deleteUser({ user_id: id });
        if (result.data.status === true) {
            toast.success('User deleted successfully', { toastId: 'userDelete_success', autoClose: 3000 });
            getUsers();
        } else {
            toast.error(result.data.message, { toastId: 'userDelete_faild', autoClose: 3000 });
        }
    }

    useEffect(() => {
        getUsers();
    }, [])

    return (
        <React.Fragment>
            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <PageBreadcrumb pageTitle={userDetails === true ? `${language.PROFILE_DETAILS}` : `${language.PROFILES}`} />
                <div className="ms-auto">
                    {userDetails === true ? <button className="btn btn-primary btn-sm" onClick={() => setUserDetails(false)} >{language.BACK_TO_LIST}</button> : 
                    <div className="btn-group">
                        <button className="btn btn-primary btn-sm"
                            onClick={() => {
                                toggle();
                                setIsAddMode('Add')
                            }}>{language.ADD}</button>
                        <Modal isOpen={modal} toggle={toggle} size="md">
                            <UserForm toggle={toggle} isAddMode={isAddMode} selectedRow={selectedRow} language={language} getUsers={getUsers} />
                        </Modal>
                    </div> }
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    {
                        userDetails === true ?
                            <><UserDetails selectedRow={selectedRow} language={language} getUsers={getUsers} /> </> :
                            <><UserList
                                users={users}
                                deleteById={deleteById}
                                getUsers={getUsers}
                                setSelectedRow={setSelectedRow}
                                handleUserStatus={handleUserStatus}
                                language={language}
                                setModal={setModal}
                                setIsAddMode={setIsAddMode}
                                setUserDetails={setUserDetails} /></>
                    }
                    {/* <UserDetails /> */}
                </div>
            </div>
        </React.Fragment>
    )
}

export default User;