import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import { ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { toast } from 'react-toastify';
import channelsServices from '../../services/channels.services';
import { FieldSchema } from '../../schema/FieldSchema';
import TextError from '../../helpers/FormikHelper/TextError';
import { countryList } from '../../helpers/Country';
import userServices from '../../services/user.services';

const UserForm = ({ toggle, isAddMode, selectedRow, language, getUsers }) => {
    const listCountry = countryList()

    const initialValues = {
        name: '',
        email: '',
        password: '',
        user_type_id: '',
        country: ''
    }

    const savedValues = {
        name: selectedRow?.name,
        email: selectedRow?.email,
        user_type_id: selectedRow?.user_type_id,
        country: selectedRow?.country,
    }

    const onSubmit = async (values) => {
        const reqBody = {
            name: values.name,
            email: values.email,
            password: values.password,
            user_type_id: values.user_type_id,
            country: values.country
        }
        if (isAddMode === 'Add') {
            let result = await userServices.createUser(reqBody);
            if (result.data.status === true) {
                toggle()
                getUsers()
                toast.success('Add User successfully', { toastId: 'registration_success', autoClose: 3000 });
            } else {
                toast.error(result.data.message, { toastId: 'registration_fail', autoClose: 3000 });
            }
        } else {
            const reqBody = {
                name: values.name,
                email: values.email,               
                user_type_id: values.user_type_id,
                country: values.country,
                user_id: selectedRow?.id
            }                 
            const result = await userServices.updateUser(reqBody);
            if (result.data.status === true) {
                toggle()
                getUsers()
                toast.success('Update User successfully', { toastId: 'update_success', autoClose: 3000 });
            } else {
                toast.error(result.data.message, { toastId: 'update_fail', autoClose: 3000 });

            }
        }       
    }

    return (
        <React.Fragment>
            <Formik
                initialValues={isAddMode === 'Add' ? initialValues : savedValues}
                validationSchema={FieldSchema(`${isAddMode === 'Add' ? 'USER_ADD' : 'USER_EDIT'}` )}
                onSubmit={onSubmit}
                enableReinitialize
            >
                {({ setFieldValue, values }) => (<Form>
                    <ModalHeader toggle={toggle} className='border-0 pb-0'>
                        <div className="card-title d-flex align-items-center mb-0">
                            <h5 className="mb-0 text-primary">{isAddMode === 'Add' ? 'Add Profile' : 'Update Profile'}</h5>
                        </div>
                    </ModalHeader>
                    <ModalBody className="">
                        <div className="row" >
                            <div className="col-12 mb-3">
                                <label htmlFor="name" className="form-label">
                                    Name
                                </label>
                                <Field type="text" className="form-control" id="name" name='name' />
                                <ErrorMessage name='name' component={TextError} />
                            </div>
                            <div className="col-12 mb-3">
                                <label htmlFor="email" className="form-label">
                                    Email
                                </label>
                                <Field type="email" className="form-control" id="email" name='email'
                                />
                                <ErrorMessage name='email' component={TextError} />
                            </div>
                            {isAddMode === 'Add' &&
                                <div className="col-12 mb-3">
                                    <label htmlFor="password" className="form-label">
                                        Password
                                    </label>
                                    <Field type="password" className="form-control" id="password" name='password'
                                    />
                                    <ErrorMessage name='password' component={TextError} />
                                </div>
                            }
                            <div className="col-md-12 mb-3">
                                <label htmlFor="user_type_id" className="form-label">
                                    User Type
                                </label>
                                <Field as='select' className='form-select' id="user_type_id" name="user_type_id" >
                                    <option disabled value="">Select User</option>
                                    <option value="2">Profile</option>
                                    <option value="3">Creator</option>
                                </Field>
                                <ErrorMessage name="user_type_id" component={TextError} />
                            </div>
                            <div className="col-12">
                                <label htmlFor="country" className="form-label">
                                    Country
                                </label>
                                <Field as='select' className='form-select' id="country" name="country" >
                                    <option disabled value="">Select User</option>
                                    {
                                        listCountry.map((e, key) => {
                                            return <option key={key} value={e.value}>{e.label}</option>;
                                        })
                                    }
                                </Field>
                                <ErrorMessage name='country' component={TextError} />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter className='border-0 pt-0'>
                        <button
                            type="button"
                            className="btn btn-secondary btn-sm"
                            onClick={toggle}
                        >
                            {language.CLOSE}
                        </button>
                        <button type="submit" className="btn btn-primary btn-sm">
                            {isAddMode === "Add" ? `${language.SAVE}` : `${language.UPDATE}`}
                        </button>
                    </ModalFooter>
                </Form>
                )}
            </Formik>
        </React.Fragment>
    );
};

export default UserForm;