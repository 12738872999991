import React from 'react';
import PageBreadcrumb from '../../components/Comman/PageBreadcrumb';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import VideosList from '../../components/Videos/VideosList';
import { useState } from 'react';
import videosServices from '../../services/videos.services';
import { useEffect } from 'react';
import { getLanguageCode } from '../../helpers/languages';
import { toast } from 'react-toastify';

const Videos = () => {
    const [blinkVideos, setBlinkVideos] = useState([]);
    const [language, setLanguage] = useState(getLanguageCode(localStorage.getItem('languageCode')?localStorage.getItem('languageCode'):'en'))

    const getVideos = async (video_type) => {
        const reqBody = {
            "video_type": video_type
        }
        let result = await videosServices.allVideos(reqBody);
        result = result.data.result;
        setBlinkVideos(result)
        // console.log(result)
    }

    const deleteById = async (id) => {              
        const result = await videosServices.deleteVideo({ video_id: id });
        if (result.data.status === true) {
            toast.success('Video deleted successfully', { toastId: 'userDelete_success', autoClose: 3000 });
            getVideos();
        } else {
            toast.error(result.data.message, { toastId: 'userDelete_faild', autoClose: 3000 });
        }
    }

    const handleSelect = (key) => {
        if (key === "blink-videos") {
            // console.log(key)
            getVideos(1);
        }
        else if (key === "short-videos") {
            // console.log(key)
            getVideos(2);
        } else {
            // console.log(key)
            getVideos(3);
        }
    }

    useEffect(() => {
        getVideos(1);
    }, [])


    return (
        <>
            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <PageBreadcrumb pageTitle={language.VIDEOS} />
            </div>
            <div className='card'>
                <div className='card-body'>
                    <Tabs
                        defaultActiveKey="blink-videos"
                        id="fill-tab-example"
                        className="mb-3 h6 tab-title-style border-0"
                        fill
                        onSelect={(e) => handleSelect(e)}
                    >
                        <Tab eventKey="blink-videos" title={language.BLINK_VIDEOS}>                           
                            <VideosList blinkVideos={blinkVideos} language={language} deleteById={deleteById} />
                        </Tab>
                        <Tab eventKey="short-videos" title={language.SHORT_VIDEOS}>
                            <VideosList blinkVideos={blinkVideos} language={language} deleteById={deleteById} />
                        </Tab>
                        <Tab eventKey="long-videos" title={language.LONG_VIDEOS}>
                            <VideosList blinkVideos={blinkVideos} language={language} deleteById={deleteById} />
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </>
    );
};

export default Videos;