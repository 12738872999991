import {API, BASE_URL} from './config';

export default {
    getReport: async () => {
        try {
            const responce = await API.get(`${BASE_URL}/user/report-user-list`);
            return responce; 
        } catch (error) {
            console.log('Report List', error)
            return error.responce;
        }
    }
}