import React, { useEffect } from 'react';
import { Modal, } from "reactstrap";
import { useState } from 'react';
// import channelsServices from '../../services/channels.services';
// import thematicChannelsServices from '../../services/thematicChannels.services';
import CMSForm from '../../components/CMS/CMSForm';
import CMSList from '../../components/CMS/CMSList';
import CMSServices from '../../services/CMS.services';
import PageBreadcrumb from '../../components/Comman/PageBreadcrumb';
import { getLanguageCode } from '../../helpers/languages';

const CMSPages = () => {
    const [modal, setModal] = React.useState(false);
    const toggle = () => setModal(!modal);
    const [getPages, setGetPages] = useState([]);
    const [selectedRow, setSelectedRow] = useState();
    const [isAddMode, setIsAddMode] = useState('Add');
    const [language, setLanguage] = useState(getLanguageCode(localStorage.getItem('languageCode')?localStorage.getItem('languageCode'):'en'))

    const getCMSPageList = async () => {
        let result = await CMSServices.getCMSList();
        result = result.data.result;
        setGetPages(result);
    }

    useEffect(() => {
        getCMSPageList();
    }, []);

    return (
        <>
            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <PageBreadcrumb pageTitle={language.CMS_PAGES}/>
                <div className="ms-auto">
                    <div className="btn-group">
                        <button className="btn btn-primary btn-sm"
                            onClick={() => {
                                toggle();
                                setIsAddMode('Add')
                            }}>{language.ADD}</button>
                        <Modal isOpen={modal} toggle={toggle} size="lg">
                            <CMSForm language={language} toggle={toggle} isAddMode={isAddMode} selectedRow={selectedRow} getCMSPageList={getCMSPageList} />
                        </Modal>
                    </div>
                </div>
            </div>
            <div className='card'>
                <div className='card-body'>
                    <CMSList language={language} getPages={getPages} setIsAddMode={setIsAddMode} setSelectedRow={setSelectedRow} setModal={setModal} />
                </div>
            </div>
        </>
    );
};

export default CMSPages;