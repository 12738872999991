import React from 'react';
import { Link } from 'react-router-dom';

const PageBreadcrumb = ({pageTitle}) => {
    return (
        <div className="ps-3">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0 p-0">
                    <li className="breadcrumb-item">
                        <Link to="/">
                            <i className="bx bx-home-alt" />
                        </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                        {pageTitle}
                    </li>
                </ol>
            </nav>
        </div>
    );
};

export default PageBreadcrumb;