import React, { useEffect } from 'react';
import { Modal, } from "reactstrap";
import ThematicChannelsList from '../../components/ThematicChannels/ThematicChannelsList';
import ThematicChannelForm from '../../components/ThematicChannels/ThematicChannelForm';
import { useState } from 'react';
import thematicChannelsServices from '../../services/thematicChannels.services';
import PageBreadcrumb from '../../components/Comman/PageBreadcrumb';
import { getLanguageCode } from "../../helpers/languages";


const ThematicChannels = () => {
    const [modal, setModal] = React.useState(false);
    const toggle = () => setModal(!modal);
    const [selectedRow, setSelectedRow] = useState();
    const [isAddMode, setIsAddMode] = useState('Add');
    const [getChannels, setGetChannels] = useState([]);
    const [language, setLanguage] = useState(getLanguageCode(localStorage.getItem('languageCode')?localStorage.getItem('languageCode'):'en'))

    const getThematicChannels = async () => {
        let result = await thematicChannelsServices.getThematicList();
        result = result.data.result;
        setGetChannels(result);
        // console.log('List', result);
    }

    useEffect(() => {
        getThematicChannels();

    }, []);
 
    return (
        <>
            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <PageBreadcrumb pageTitle={language.THEMATIC_CHANNELS} />
                <div className="ms-auto">
                    <div className="btn-group">
                        <button className="btn btn-primary btn-sm"
                            onClick={() => {
                                toggle();
                                setIsAddMode('Add')
                            }}><i className="bx bx plus" />{language.ADD}</button>
                        <Modal isOpen={modal} toggle={toggle} size="md">
                            <ThematicChannelForm toggle={toggle} isAddMode={isAddMode} selectedRow={selectedRow} getThematicChannels={getThematicChannels} language={language} />
                        </Modal>
                    </div>
                </div>
            </div>
            <div className='card'>
                <div className='card-body'>
                    <ThematicChannelsList getChannels={getChannels} setIsAddMode={setIsAddMode} setSelectedRow={setSelectedRow} setModal={setModal} language={language} />
                </div>
            </div>
        </>
    );
};

export default ThematicChannels;