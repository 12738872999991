import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Login from '../pages/Authentication/Login';
// import Error404 from '../pages/Errors/Error404';
import PrivateRoutes from './PrivateRoutes';
import Logout from '../pages/Authentication/Logout';
import Test from '../pages/User/Test';
import Dashboard from '../pages/Dashboard/Dashboard';
import ThematicChannels from '../pages/ThematicChannels/ThematicChannels';
import Channels from '../pages/Channels/Channels';
import CMSPages from '../pages/CMSPages/CMSPages';
import User from '../pages/User/User';
import Error404 from '../pages/Error/Error404';
import ReportedUser from '../pages/ReportedUser/ReportedUser';
import Videos from '../pages/Videos/Videos';
import Profile from '../pages/User/Profile';
import Messages from '../pages/Messages/Messages';

const AppRoute = () => {
    return (
        <Router>
            <Routes>
              <Route element={<PrivateRoutes/>}>
                  <Route path='/' element={<Dashboard />} />
                  <Route path='/profiles' element={<User />} />                 
                  <Route path='/profile' element={<Profile />} />                 
                  <Route path='/thematic-channels' element={<ThematicChannels />} />
                  <Route path='/channels' element={<Channels />} />
                  <Route path='/cms-pages' element={<CMSPages />} />
                  <Route path='/reported-user' element={<ReportedUser />} />
                  <Route path='/videos' element={<Videos />} />
                  <Route path='/messages' element={<Messages />} />
                  <Route path='/logout' element={<Logout />} />
              </Route>
              <Route path='/login' element={<Login/>}/>
              <Route path="*" element={<Error404 />} />
              <Route path='/share' element={<Test />} />
            </Routes>
        </Router>
      );
    }
  export default AppRoute;
