import React from 'react';
import { Row, Col, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { useMemo } from "react";
import { Link } from 'react-router-dom';
import { BASE_URL } from '../../services/config';
import { formattedDate } from '../../helpers/Helper';

const { SearchBar } = Search;
const VideosList = ({ blinkVideos, language, deleteById }) => {
    
    let dataList = blinkVideos.map((item, index) => {
        item.srNo = index + 1
        return item
    })

    console.log('asd',dataList)
    const tblColumn = useMemo(() => [
        {
            dataField: "srNo",
            text: `${language.SRNO}`,
        },
        {
            dataField: "user.name",
            text: `${language.NAME}`,
            sort: true,
        },
        {
            dataField: "like_count",
            text: `${language.LIKE}`,
            sort: true,
        },
        {
            dataField: "share_count",
            text: `${language.SHARE}`,
            sort: true,
        },
        {
            dataField: "upload_date",
            text: `${language.DATE}`,
            sort: true,
            formatter: (value, row) => {
                return <span>{formattedDate(row.upload_date)}</span>
            }
        },
        {
            dataField: "comment_count",
            text: `${language.COMMENT}`,
            sort: true,
        },
        {
            dataField: "favourite_count",
            text: `${language.FAVOURITE}`,
            sort: true,
        },
        {
            dataField: "video_id",
            text: `${language.VIDEO_ID}`,
            sort: true,
        },
        {
            dataField: "url",
            text: `${language.VIEW_VIDEO}`,
            formatter: (value, row) => {
                return (
                    <>
                        <UncontrolledDropdown>
                            <DropdownToggle className="card-drop" tag="a">
                                <i className="lni lni-more-alt font-size-16" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end action_drop_down">
                                <DropdownItem href={`${BASE_URL}/${row.url}`} target="_blank" rel="noopener noreferrer">
                                    <i className="lni lni-eye font-size-16 text-primary me-1" />
                                    View
                                </DropdownItem>
                                <DropdownItem
                                    onClick={() => {
                                        deleteById(row.id);
                                    }}
                                >
                                    <i className="lni lni-trash font-size-16 text-danger me-1" />
                                    Delete
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>

                    </>
                );
            },
        },
    ]);

    const defaultSorted = [
        {
            dataField: "_id",
            order: "desc",
        },
    ];
    const pzipcodeOptions = {
        sizePerPzipcode: 10,
        totalSize: dataList.length, // replace later with size(customers),
        custom: true,
    };

    return (
        <>
            <PaginationProvider
                pagination={paginationFactory(pzipcodeOptions)}
                keyField="id"
                columns={tblColumn}
                data={dataList}
            >
                {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider keyField="_id" columns={tblColumn} data={dataList} search>
                        {(toolkitProps) => (
                            <React.Fragment>
                                <Row className="mb-2" style={{ display: "none" }}>
                                    <Col md="4">
                                        <div className="search-box me-2 mb-2 d-inline-block">
                                            <div className="status-relative"></div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl="12">
                                        <div className="text-right col-md-3 offset-md-9 mb-2">
                                            <label className="pe-3 text-white">{language.SEARCH}</label>
                                            <SearchBar
                                                className="w-100"
                                                {...toolkitProps.searchProps}
                                            />
                                        </div>
                                        <div className="table-responsive">
                                            <BootstrapTable
                                                noDataIndication={
                                                    <h6 className="d-flex justify-content-center">
                                                        {language.RECORD_NOT_FOUND}
                                                    </h6>
                                                }
                                                keyField={"id"}
                                                responsive
                                                bordered={false}
                                                striped={false}
                                                defaultSorted={defaultSorted}
                                                classes={
                                                    "table table-striped align-middle table-nowrap"
                                                }
                                                headerWrapperClasses={"thead-light"}
                                                {...toolkitProps.baseProps}
                                                {...paginationTableProps}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                {dataList.length > 0 && (
                                    <Row className="align-items-md-center mt-30">
                                        <Col className="inner-custom-pagination mt-2 d-flex">
                                            <div className="d-inline">
                                                <SizePerPageDropdownStandalone {...paginationProps} />
                                            </div>
                                            <div className="text-md-right ms-auto">
                                                <PaginationListStandalone {...paginationProps} />
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                            </React.Fragment>
                        )}
                    </ToolkitProvider>
                )}
            </PaginationProvider>
        </>
    );
};

export default VideosList;