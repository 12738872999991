import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';  
import Layout from '../components/Layout/Layout';
import { getLoggedData } from '../helpers/Helper';

const PrivateRoutes = () => {
  const getUserData = getLoggedData();
    // let auth = localStorage.getItem('auth')
  // localStorage.setItem('auth', auth.token);
  return (
    getUserData?.auth === true ? <><Layout /></> : <Navigate to='/login' />
  )
}
export default PrivateRoutes;